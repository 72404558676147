<template>
  <el-container>
    <!-- 头部 -->
    <el-header>
      <div>
        <!-- <img src="../assets/logo.png" alt /> -->
        <span>江溪街道污染物在线监测系统</span>
      </div>
      <el-button type="info" @click="logout">退出</el-button>
    </el-header>
    <!-- 主体 -->
    <el-container>
      <!-- 侧边栏 -->
      <el-aside :width="isCollapse ? '64px' : '200px'">
        <div class="toggle-button" @click="togleCollapse">|||</div>
        <el-menu
          unique-opened
          :collapse="isCollapse"
          :collapse-transition="false"
          router
          :default-active="$route.path"
          background-color="#333744"
          text-color="#fff"
          active-text-color="#409FFF"
        >
          <!-- :unique-opened="true"->只允许展开一个菜单 -->
          <!-- :collapse-transition="false" -> 关闭动画 -->
          <!-- router -> 导航开启路由模式 -->
          <!-- 一级菜单  -->
          <el-submenu
            :index="item.id + ''"
            v-for="item in menuList"
            :key="item.id"
          >
            <!-- 一级菜单的模板区域 -->
            <template slot="title">
              <i :class="item.iconid"></i>

              <span>{{ item.authName }}</span>
            </template>
            <!-- 二级菜单 -->
            <el-menu-item
              :index="'/' + subItem.path"
              v-for="subItem in item.children"
              :key="subItem.id"
              @click="saveNavState('/' + subItem.path)"
            >
              <!-- 导航开启路由模式：
                                将index值作为导航路由 -->
              <!-- 二级菜单的模板区域 -->
              <template slot="title">
                <i class="el-icon-menu"></i>
                <!-- {{ subItem }} -->
                <span>{{ subItem.authName }}</span>
              </template>
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </el-aside>
      <!-- 内容主体 -->
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      menuList: [
        {
          authName: "预警管理",
          id: "125",
          iconid: "el-icon-delete",
          children: [
            {
              authName: "预警配置",
              id: "125-1",
              path: "backmanger/earlywaringmanage",
            },
            {
              authName: "数据查询",
              id: "125-2",
              path: "daily/searchdata",
            },
            {
              authName: "历史预警",
              id: "125-3",
              path: "backmanger/historywaring",
            },
						{
							authName: "标况实况数据",
							id: "125-4",
							path: "backmanger/realtimeauditing",
						},
						{
							authName: "标况实况图表",
							id: "125-5",
							path: "backmanger/realtimedatacavans",
						},

          ],
        },
        {
          authName: "日报管理",
          id: "121",
          iconid: "el-icon-delete",
          children: [
            {
              authName: "年月日数据",
              id: "121-2",
              path: "backmanger/alldate",
            },
          ],
        },
      ],
      // 默认不折叠
      isCollapse: false,
      // 被激活导航地址
      activePath: "",
    };
  },
  created() {
    // this.activePath = window.sessionStorage.getItem("activePath");
  },
  methods: {
    logout() {
      // 清空token

      this.$router.push("/index");
    },
    // 获取请求菜单
    // async getMenuList() {
    //     const { data: res } = await this.$http.get("menus");
    //     if (res.meta.status !== 200) return this.$message.error(res.meta.msg);
    //     this.menuList = res.data;
    //     //
    // },
    // 菜单的折叠与展开
    togleCollapse() {
      this.isCollapse = !this.isCollapse;
    },
    // 保存连接的激活地址
    saveNavState(activePath) {
      console.log(activePath);
      this.$router.push(activePath);
      window.sessionStorage.setItem("activePath", "/" + activePath);
    },
  },
};
</script>

<style lang="less" scoped>
.el-container {
  height: 100%;
}

.el-header {
  background-color: #373f41;
  display: flex;
  justify-content: space-between;
  padding-left: 0;
  align-items: center;
  color: #fff;
  font-size: 20px;

  > div {
    display: flex;
    align-items: center;

    img {
      height: 40px;
    }

    span {
      margin-left: 15px;
    }
  }
}

.el-aside {
  background-color: #333744;

  .el-menu {
    border: none;
  }
}

.el-main {
  background-color: #eaedf1;
}

.iconfont {
  margin-right: 10px;
}

.toggle-button {
  background-color: #4a5064;
  font-size: 10px;
  line-height: 24px;
  color: #fff;
  text-align: center;
  letter-spacing: 0.2em;
  // 鼠标放上去变成小手
  cursor: pointer;
}
</style>
